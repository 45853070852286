import '@fontsource-variable/dm-sans';
import '@fontsource-variable/open-sans';
import 'cal-heatmap/cal-heatmap.css';
import 'leaflet/dist/leaflet.css';
import './index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekday from 'dayjs/plugin/weekday';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { lazy, StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import ReactLazyLoading from '@components/ReactLazyLoading.tsx';
import { APP_NAME } from '@const';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-license';

import AppAuth from './AppAuth.tsx';
import AuthProvider from './AuthProvider.tsx';
import HelmetWrapper from './HelmetWrapper.tsx';
import InvalidUrlRedirect from './invalidUrlRedirect.tsx';
import { store } from './store.ts';
import theme from './theme.ts';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(isoWeek);
dayjs.extend(weekday);
dayjs.extend(isSameOrBefore);

const Stats = lazy(() => import('./Views/Stats/'));
const AppView = lazy(() => import('./AppView.tsx'));
const Tacklebox = lazy(() => import('./Views/Tacklebox/'));

LicenseInfo.setLicenseKey('4f9bde78dd06877f6b33447da35e3d85Tz04ODc0MSxFPTE3NDUwNzI2MzgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ToastContainer position="top-center" />
            <AppAuth>
              <BrowserRouter
                future={{
                  v7_startTransition: true,
                  v7_relativeSplatPath: true
                }}
              >
                <Routes>
                  <Route
                    path="/:campaign_id?"
                    element={
                      <HelmetWrapper title={APP_NAME}>
                        <Suspense fallback={<ReactLazyLoading />}>
                          <AppView />
                        </Suspense>
                      </HelmetWrapper>
                    }
                  />
                  <Route
                    path="/tacklebox"
                    element={
                      <HelmetWrapper title={APP_NAME + ': Tacklebox'}>
                        <Suspense fallback={<ReactLazyLoading />}>
                          <Tacklebox />
                        </Suspense>
                      </HelmetWrapper>
                    }
                  />
                  <Route path="/stats" element={<Navigate to="/stats/datasets" replace />} />
                  <Route
                    path="/stats/:tab?"
                    element={
                      <HelmetWrapper title={APP_NAME + ': Stats'}>
                        <Suspense fallback={<ReactLazyLoading />}>
                          <Stats />
                        </Suspense>
                      </HelmetWrapper>
                    }
                  />
                  <Route path="*" element={<InvalidUrlRedirect />} />
                </Routes>
              </BrowserRouter>
            </AppAuth>
          </ThemeProvider>
        </AuthProvider>
      </HelmetProvider>
    </Provider>
  </StrictMode>
);
