import { MenuProps } from '@mui/material';
import { SsidChartOutlined, FactCheckOutlined, ListAltOutlined, HomeRepairServiceOutlined } from '@mui/icons-material';
import { NavbarOptions } from './Views/Stats/types/Navbar';
import { hexToRgb, idFromMapboxStyle, nameFromMapboxStyle } from './utils';
import { interpolateViridis } from 'd3-scale-chromatic';
import { Basemap } from '@types';

export const AUTH_FALLBACK = {
  loginWithRedirect: () => null,
  getAccessTokenSilently: async () => '',
  user: {},
  error: null,
  isLoading: false,
  logout: () => undefined
};

export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoic3VtYXJsaWRhc29uIiwiYSI6ImNsNG4yMGdoYjBjdnczanE4N3pyZXpkd2YifQ.5IFs5KRgvf8tSOklX2pu9g';
const styleUrls = [
  'mapbox://styles/mapbox/light-v11',
  'mapbox://styles/mapbox/dark-v11',
  'mapbox://styles/mapbox/streets-v12',
  'mapbox://styles/mapbox/outdoors-v12',
  'mapbox://styles/mapbox/navigation-day-v1',
  'mapbox://styles/mapbox/navigation-night-v1',
  'mapbox://styles/mapbox/satellite-v9'
];

export const BASEMAPS: Basemap[] = [
  ...styleUrls.map((url) => ({
    id: idFromMapboxStyle(url),
    name: nameFromMapboxStyle(url),
    url,
    type: 'style' as const,
    layers: []
  })),
  {
    id: 'osm',
    name: 'OpenStreetMap',
    url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
    type: 'tilelayer' as const,
    layers: []
  },
  {
    id: 'google_satellite',
    name: 'Google Satellite',
    url: 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    type: 'tilelayer' as const,
    layers: []
  }
];

export const BANNER_CONTENTS_S = 'UNCLASSIFIED';
export const BANNER_COLOR_S = 'green';
export const BANNER_CONTENTS_TS = 'UNCLASSIFIED';
export const BANNER_COLOR_TS = 'green';
export const CLASSIFICATION_BANNER_FONT_SIZE = 1.2; // * 8
export const CLASSIFICATION_BANNER_SIZE = CLASSIFICATION_BANNER_FONT_SIZE + 1.0;

export const MENU_ITEM_MIN_WIDTH = 200;
export const MAP_PANEL_MAX_HEIGHT = 350;
export const MAP_TRANSITION_ZOOM = 10.2;
export const MAX_ROWS = 10_001;
export const REFETCH_THRESHOLD = 0.9;
export const EGE_LABEL = 'EGE';
export const LEADS_LABEL = 'LEADs';
export const APP_NAME = 'LEADs';
export const EGE_H3_ZOOM_THRESHOLD = 6.5;

export const NOTIFICATION_TYPE_TITLE = {
  MOTD: 'Message of the Day',
  JOB_UPDATE: 'Job Update',
  ALERT: 'Alert',
  TACKLEBOX: 'Tacklebox',
  CAMPAIGN: 'Campaign'
};

export const MENU_PROPS_OPEN_TO_LEFT: Partial<Omit<MenuProps, 'children'>> = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right'
  }
};

export const NAVBAR_VIEWS: NavbarOptions = {
  // home: { displayName: 'Home', icon: SsidChartOutlined, path: '/', group: 'main' },
  flycaster: { displayName: 'Flycaster', icon: FactCheckOutlined, path: '/', group: 'main', enabled: true },
  tacklebox: { displayName: 'Tacklebox', icon: HomeRepairServiceOutlined, path: '/tacklebox', group: 'main', enabled: true },
  // alerts: { displayName: 'Alerts', icon: ListAltOutlined, path: '/', group: 'main' },

  datasets: { displayName: 'Stats', icon: SsidChartOutlined, path: '/stats/datasets', group: 'stats', enabled: true },
  dictionary: { displayName: 'Dictionary', icon: ListAltOutlined, path: '/stats/dictionary', group: 'stats', enabled: true },
  integrity: { displayName: 'Integrity', icon: FactCheckOutlined, path: '/stats/integrity', group: 'stats', enabled: true }
};

interface TopicColor {
  light: string;
  dark: string;
  mapPolygonFillColor: [number, number, number, number];
  mapPolygonBorderColorSelected: [number, number, number, number];
}
export const TOPIC_COLORS: Record<string, TopicColor> = {
  airfield: {
    light: '#a5a6b6',
    dark: '#8664c4',
    mapPolygonFillColor: hexToRgb('#8664c4', 120),
    mapPolygonBorderColorSelected: hexToRgb('#8664c4', 255)
  },
  military: {
    light: '#FFD5D5',
    dark: '#FF8080',
    mapPolygonFillColor: hexToRgb('#FF8080', 120),
    mapPolygonBorderColorSelected: hexToRgb('#FF8080', 255)
  },
  diplomatic: {
    light: '#b3bfdd',
    dark: '#4963a1',
    mapPolygonFillColor: hexToRgb('#4963a1', 120),
    mapPolygonBorderColorSelected: hexToRgb('#4963a1', 255)
  },
  university: {
    dark: '#D9D0C9',
    light: '#F2EFE9',
    mapPolygonFillColor: hexToRgb('#D9D0C9', 120),
    mapPolygonBorderColorSelected: hexToRgb('#D9D0C9', 255)
  }
};
export type Topic = keyof typeof TOPIC_COLORS;
export const TOPICS: Topic[] = Object.keys(TOPIC_COLORS);

export const TOPIC_COLOR_UNKNOWN = '#FFFF00';
export const TOPIC_COLOR_UNKNOWN_RGB = [255, 255, 0, 255] as [number, number, number, number];
export const MAX_ANIMATED_ZOOM = 13.5;
export const CENTROID_TO_BOUNDING_BOX_OFFSET = 0.005;

export const H3_RAW_SCALE_COLORS_CSS: [number, number, number, number][] = [
  // [247, 247, 247, 100],
  [204, 204, 204, 0.39],
  [164, 164, 164, 0.39],
  [124, 124, 124, 0.39],
  [84, 84, 84, 0.39],
  [0, 0, 0, 0.39]
];
export const H3_SCALE_COLORS_DECKGL: [number, number, number, number][] = [
  // [247, 247, 247, 100],
  [204, 204, 204, 100],
  [164, 164, 164, 100],
  [124, 124, 124, 100],
  [84, 84, 84, 100],
  [0, 0, 0, 100]
];

export const H3_CLUSTER_SCALE_COLORS_DECKGL: [number, number, number, number][] = [
  [173, 216, 230, 100], // Light blue
  [135, 206, 250, 100], // Sky blue
  [70, 130, 180, 100], // Steel blue
  [30, 144, 255, 100], // Dodger blue
  [0, 0, 139, 100] // Dark blue
];

export const VIRIDIS_SCALE_COLORS_DECKGL = [
  // \n
  interpolateViridis(1),
  interpolateViridis(0.75),
  interpolateViridis(0.5),
  interpolateViridis(0.25),
  interpolateViridis(0)
].map((color) => hexToRgb(color, 100));

export const VIRIDIS_SCALE_COLORS_CSS = [
  // \n
  interpolateViridis(1),
  interpolateViridis(0.75),
  interpolateViridis(0.5),
  interpolateViridis(0.25),
  interpolateViridis(0)
].map((color) => hexToRgb(color, 0.39));
