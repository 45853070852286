import { Topic, TOPIC_COLOR_UNKNOWN, TOPIC_COLORS } from '@const';
import { Audience, MapBounds } from '@types';
import * as turf from '@turf/turf';
import { Feature, FeatureCollection } from 'geojson';
import { LngLat } from 'react-map-gl';

// Function overload signatures
export function hexToRgb(hex: string): [number, number, number];
export function hexToRgb(hex: string, alpha: number): [number, number, number, number];

// Function implementation
export function hexToRgb(hex: string, alpha?: number): [number, number, number] | [number, number, number, number] {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^\s*#|\s*$/g, '');

  // If the string is a shorthand three digit hex code, convert it to six digits
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(function (hex) {
        return hex + hex;
      })
      .join('');
  }

  // Convert to RGB values
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  if (alpha !== undefined) {
    return [r, g, b, alpha];
  } else {
    return [r, g, b];
  }
}

export const hexToRgbString = (hex: string, alpha?: number) => {
  return 'rgba(' + hexToRgb(hex).join(', ') + (alpha !== undefined ? ', ' + alpha : '') + ')';
};

export const getBoundsOfWktPolygon = (wkt: string): null | [number, number, number, number] => {
  // Check if the input is a valid WKT Polygon string
  if (!wkt.startsWith('POLYGON') || !wkt.endsWith('))')) {
    return null;
  }

  // Extract the coordinates string and split into coordinate pairs
  const si = wkt.indexOf('(');
  const ei = wkt.indexOf(')');
  const coordinatesString = wkt.slice(si + 2, ei);
  const coordinatePairs = coordinatesString.split(',').map((d) => d.trim());
  // Initialize bounds
  let minX = Infinity,
    minY = Infinity,
    maxX = -Infinity,
    maxY = -Infinity;

  // Iterate over each coordinate pair to find the min and max of x and y
  coordinatePairs.forEach((pair) => {
    const [x, y] = pair.split(' ').map(Number);
    minX = Math.min(minX, x);
    minY = Math.min(minY, y);
    maxX = Math.max(maxX, x);
    maxY = Math.max(maxY, y);
  });
  // Return the bounds as an object with minX, minY, maxX, maxY
  return [minX, minY, maxX, maxY];
};

export const calculateMonthsElapsed = (startDate: string, endDate: string) => {
  // Parse the start and end dates to Date objects if they are not already
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate the difference in months and years
  let months = (end.getFullYear() - start.getFullYear()) * 12;
  months -= start.getMonth();
  months += end.getMonth();

  // Adjust if the end day is earlier in the month than the start day
  if (end.getDate() < start.getDate()) {
    months--;
  }

  return months;
};

export const secondsToHumanReadable = (seconds: number, shortForm: boolean = false): string => {
  // Define the duration of each time unit in seconds
  const day = 86400; // 60 * 60 * 24
  const hour = 3600; // 60 * 60
  const minute = 60;

  // Calculate the days, hours, minutes, and remaining seconds
  const days = Math.floor(seconds / day);
  const hours = Math.floor((seconds % day) / hour);
  const minutes = Math.floor((seconds % hour) / minute);
  const remainingSeconds = Math.round(seconds % minute);

  // Define full and short-form units
  const unitMap = shortForm ? { day: 'd', hour: 'h', minute: 'm', second: 's' } : { day: 'day', hour: 'hour', minute: 'minute', second: 'second' };

  // Build the output string
  let result = '';
  if (days > 0) result += `${days} ${unitMap.day}${!shortForm && days > 1 ? 's' : ''}, `;
  if (hours > 0) result += `${hours} ${unitMap.hour}${!shortForm && hours > 1 ? 's' : ''}, `;
  if (minutes > 0) result += `${minutes} ${unitMap.minute}${!shortForm && minutes > 1 ? 's' : ''}, `;
  if (remainingSeconds > 0) result += `${remainingSeconds} ${unitMap.second}${!shortForm && remainingSeconds > 1 ? 's' : ''}, `;

  // Remove the trailing comma and space, if any
  return result.endsWith(', ') ? result.slice(0, -2) : result || `0 ${shortForm ? 's' : 'seconds'}`;
};

export const extractInitials = (email: string) => {
  // Split the email address by '@' to get the username part
  const username = email.split('@')[0];

  // Split the username by '.' to get the parts
  const parts = username.split('.');

  // Extract the first letter of each part
  const initials = parts.map((part) => part.charAt(0).toUpperCase()).join('');

  return initials;
};

export const parseBoundingBox = (boundingBoxString: string) => {
  // Remove 'BOX(' and ')' from the string
  const coordinatesString = boundingBoxString.replace('BOX(', '').replace(')', '');
  // Split the coordinates string into an array of strings
  const coordinatesArray = coordinatesString.split(/[,\s]+/);

  // Parse the strings to numbers
  const minLon = parseFloat(coordinatesArray[0]);
  const minLat = parseFloat(coordinatesArray[1]);
  const maxLon = parseFloat(coordinatesArray[2]);
  const maxLat = parseFloat(coordinatesArray[3]);

  return { minLon, minLat, maxLon, maxLat };
};

export const findClosest = <T>(arr: { value: number }[], x: number): T => {
  // Use reduce to find the object with the closest value to x
  return arr.reduce((closest, current) => {
    // Compare the absolute difference between x and the current value
    return Math.abs(current.value - x) < Math.abs(closest.value - x) ? current : closest;
  }) as T;
};

export const getMinMax = (arr: { record_created_epoch: number }[]) => {
  if (arr.length === 0) return {}; // Handle empty array case

  let min = arr[0].record_created_epoch;
  let max = arr[0].record_created_epoch;

  for (let i = 1; i < arr.length; i++) {
    const currentValue = arr[i].record_created_epoch;

    if (currentValue < min) {
      min = currentValue;
    }
    if (currentValue > max) {
      max = currentValue;
    }
  }

  return { min, max };
};

export const findMissingEpochs = (dates: number[], startEpoch: number, endEpoch: number) => {
  const oneDayInMillis = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

  // Sort the input array of epochs
  const sortedEpochs = dates.sort((a, b) => a - b);

  let currentEpoch = startEpoch;
  const missingEpochs = [];

  // Loop through the sorted epochs and check for missing days
  for (let i = 0; i < sortedEpochs.length; i++) {
    while (currentEpoch < sortedEpochs[i]) {
      missingEpochs.push(currentEpoch);
      currentEpoch += oneDayInMillis; // Move to the next day
    }
    // Move to the next day after the current epoch
    currentEpoch += oneDayInMillis;
  }

  // Check if there are any missing days between the last epoch and the end date
  while (currentEpoch <= endEpoch) {
    missingEpochs.push(currentEpoch);
    currentEpoch += oneDayInMillis;
  }

  return missingEpochs;
};

export const findMissingDates = (dates: string[], startDate: string, endDate: string) => {
  const me = findMissingEpochs(
    dates.map((d) => new Date(d).getTime()),
    new Date(startDate).getTime(),
    new Date(endDate).getTime()
  );
  return me.map((d) => new Date(d).toISOString());
};

export const getRandomColor = () => {
  return `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0')}`;
};

export const hexColorForAudience = (audience: Audience, colorOverrides: Record<string, string>) => {
  let overrideColor;
  let defaultColor = TOPIC_COLOR_UNKNOWN;
  if (audience.categories && audience.categories.length > 0) {
    const topicColor = TOPIC_COLORS[audience.categories[0] as Topic];
    if (topicColor) {
      defaultColor = topicColor.dark;
    } else {
      console.error('hexColorForAudience(): Unknown category:', JSON.stringify(audience));
    }
  } else {
    console.error('hexColorForAudience(): Malformed Entity:', JSON.stringify(audience));
  }
  if (colorOverrides[audience.advertiser_id]) {
    overrideColor = colorOverrides[audience.advertiser_id];
  }
  return [defaultColor, overrideColor];
};

export function generateUniqueIdentifier() {
  // Generate a version 4 UUID
  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  // Concatenate UUID with current timestamp
  return uuidv4() + '-' + Date.now();
}

export const isBboxWithin = (bbox1: MapBounds, bbox2: number[]) => {
  if (!bbox1) return false;
  // Returns a (minx, miny, maxx, maxy) tuple (float values) that bounds the object.
  return bbox1.southWest.lat >= bbox2[1] && bbox1.southWest.lng >= bbox2[0] && bbox1.northEast.lat <= bbox2[3] && bbox1.northEast.lng <= bbox2[2];
};

export const mapBoundsWKT = (mapBounds: MapBounds) => {
  const neLat = mapBounds?.northEast.lat;
  const neLng = mapBounds?.northEast.lng;
  const swLat = mapBounds?.southWest.lat;
  const swLng = mapBounds?.southWest.lng;
  return 'POLYGON((' + swLng + ' ' + swLat + ',' + swLng + ' ' + neLat + ',' + neLng + ' ' + neLat + ',' + neLng + ' ' + swLat + ',' + swLng + ' ' + swLat + '))';
};

export const formatWKTPolygon = (startLnglat: LngLat, currentLnglat: LngLat) => {
  const { lat: startLat, lng: startLng } = startLnglat;
  const { lat: currentLat, lng: currentLng } = currentLnglat;

  // Determine the rectangle's boundaries
  const minLat = Math.min(startLat, currentLat); // Bottom
  const maxLat = Math.max(startLat, currentLat); // Top
  const minLng = Math.min(startLng, currentLng); // Left
  const maxLng = Math.max(startLng, currentLng); // Right

  // Define the rectangle's corners
  const polygonCoordinates = [
    `${minLng} ${minLat}`, // Bottom-left corner
    `${minLng} ${maxLat}`, // Top-left corner
    `${maxLng} ${maxLat}`, // Top-right corner
    `${maxLng} ${minLat}`, // Bottom-right corner
    `${minLng} ${minLat}` // Close the polygon by repeating the first point
  ];

  // Format as WKT
  const wktPolygon = `POLYGON((${polygonCoordinates.join(', ')}))`;
  return wktPolygon;
};

export const calculateFeatureArea = (feature: Feature) => {
  const featureCollection: FeatureCollection = {
    type: 'FeatureCollection',
    features: [feature]
  };

  const squareMeters = turf.area(featureCollection);
  const squareKilometers = squareMeters / 1_000_000;
  return Math.round(squareKilometers);
};

export const formatPred = (input: undefined | null | number) => {
  if (input === undefined || input === null) {
    return null;
  }
  // Convert input to a string, assuming it's a number like "0.9999993523"
  const strValue = input.toString();

  // Find the decimal point and get first two digits after it
  const decimalIndex = strValue.indexOf('.');
  if (decimalIndex === -1) {
    return '100%'; // If there's no decimal point, return 100%
  }

  // Extract the first four digits after the decimal for precision (x.xxx9 -> 99.99%)
  const percentageValue = strValue.slice(0, decimalIndex + 5); // Take 4 digits after the decimal point
  const parsedValue = parseFloat(percentageValue) * 100;

  // Ensure two decimal places formatting
  const formattedValue = parsedValue.toFixed(2);

  return formattedValue + '%';
};

export const orderedObjectString = (obj: Record<string, unknown>): string => {
  const entries = Object.entries(obj).sort(([a], [b]) => a.localeCompare(b));
  return JSON.stringify(entries);
};

export const formatToShortNumber = (num: number, decimals: number = 1): string => {
  if (num < 1_000) {
    return num.toString(); // Return the number as-is if less than 1,000
  }

  const suffixes = ['K', 'M'];
  const factor = Math.floor((num.toString().length - 1) / 3);
  const shortNumber = num / Math.pow(1_000, factor);
  const formattedNumber = shortNumber.toFixed(decimals);

  return `${formattedNumber.replace(/\.0+$/, '')}${suffixes[factor - 1]}`;
};

export const nameFromMapboxStyle = (url: string) => {
  // Extract the style part from the URL
  const stylePart = url.split('/').pop(); // Gets the last part (e.g., "navigation-night-v1")

  if (!stylePart) return 'UNKNOWN';

  // Remove version suffix (e.g., "-v1", "-v12", etc.)
  const styleName = stylePart.replace(/-v\d+$/, '');

  // Replace hyphens with spaces and capitalize words
  return styleName
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const idFromMapboxStyle = (url: string) => {
  return url
    .replace('mapbox://', '') // Remove the "mapbox://" prefix
    .replace(/\//g, '_') // Replace slashes with underscores
    .replace(/-/g, '_'); // Replace hyphens with underscores
};

export const formatNumber = (num: number, decimals: number = 1): string => {
  if (num < 1000) return num.toString(); // No formatting for numbers < 1000

  const suffixes = ['', 'K', 'M', 'B', 'T'];
  const tier = Math.floor(Math.log10(Math.abs(num)) / 3); // Determines the suffix index

  if (tier === 0) return num.toString(); // Safety check

  const scaled = num / Math.pow(10, tier * 3); // Scale the number
  const rounded = scaled.toFixed(decimals); // Round to specified decimal places

  return `${rounded.replace(/\.0$/, '')}${suffixes[tier]}`;
};
