import { createTheme } from '@mui/material/styles';
// import { PaletteOptions } from '@mui/material/styles';

// Extend the Palette and PaletteOptions interfaces to include 'tertiary'
declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary']; // Use the same structure as primary
  }
  interface PaletteOptions {
    tertiary?: PaletteOptions['primary'];
  }
}

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      light: '#0AE2FF',
      main: '#00ACC1',
      dark: '#007E8F'
    },
    secondary: {
      light: '#FFF0C2',
      main: '#FFE082',
      dark: '#FFD147'
    },
    tertiary: {
      light: '#F1F8E9',
      main: '#547C27',
      dark: '#354E18'
    },
    text: {
      primary: '#212120',
      secondary: '#6B6B6B'
    },
    background: {
      default: '#FFFFFF'
    },
    divider: '#E0E0E0'
  },
  typography: {
    fontFamily: '"Open Sans Variable"'
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '150%',
          letterSpacing: '0.15px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
          fontSize: '12px',
          fontStyle: 'normal'
        }
      }
    }
  }
});

export default theme;
